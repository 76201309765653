// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/maps";

// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/badge";

// 5. Add additional custom code here
@import url('https://fonts.googleapis.com/css?family=Raleway:300,700&subset=latin-ext');

html {
   @include media-breakpoint-up(lg) {
      background: url(img/background.jpg) no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      // TODO dát 100% div kvůli IE
      //filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./img/background.jpg', sizingMethod='scale');
      //-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='img/background.jpg', sizingMethod='scale')";
   }
}
body {
   color: #5f5145;
   font-family: 'Raleway', sans-serif;
   background: none;
}
.photo {
   justify-content: center;
   flex-direction: column;
   text-align: center;
   align-items: flex-end;
   img {
      max-height: 15vh;
      min-height: 100px;
      margin: 2em auto 2em auto;

      @include media-breakpoint-up(sm) {
         max-height: 20vh;
         min-height: 200px;
      }
      @include media-breakpoint-up(md) {
         margin: 3em auto 3em auto;
      }
      @include media-breakpoint-up(lg) {
         display: none;
      }
   }
}
.about-me {
   flex-direction: column;
   justify-content: center;

   @keyframes fadein {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }

   /* Firefox < 16 */
   @-moz-keyframes fadein {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }

   /* Safari, Chrome and Opera > 12.1 */
   @-webkit-keyframes fadein {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }

   /* Internet Explorer */
   @-ms-keyframes fadein {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }

   /* Opera < 12.1 */
   @-o-keyframes fadein {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }

   h1 {
      letter-spacing: 0.02em;
   }
   p {
      line-height: 1.75em;
      font-size: 1.1em;
      letter-spacing: 0.02em;
   }
   a {
      color: #5f5145;
      text-decoration: underline;
      &:hover {
         text-decoration: none;
      }
   }

   @include media-breakpoint-up(md) {
      .text-wrapper{
         padding: 0 10%;
         max-width: 630px;
         margin: auto;
      }
   }

   @include media-breakpoint-up(lg) {
      padding: 5em 4em 5em 5em;
      min-height: 100vh;
      background: rgba(255, 255, 255, 1);
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 6%, rgba(255,255,255,0.5) 100%);
      -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 1.5s; /* Firefox < 16 */
      -ms-animation: fadein 1.5s; /* Internet Explorer */
      -o-animation: fadein 1.5s; /* Opera < 12.1 */
      animation: fadein 1.5s;
      .text-wrapper{
         padding: 0;
      }
   }

   @include media-breakpoint-up(xl) {
      padding: 5em 10% 5em 10%;
   }

}
.contact {
   padding: 0;
   margin-bottom: 0; //bootstrap __reboot
   li {
      list-style-type: none;
      line-height: 1.75em;
      font-size: 1.1em;
      letter-spacing: 0.02em;
   }
   a {
      color: #5f5145;
      text-decoration: underline;
      &:hover {
         text-decoration: none;
      }
   }
}
.social {
   // TODO flex
   padding: 2em 0;
   margin-bottom: 0 !important; //bootstrap _spacing
   justify-content: center;
   .badge {
      padding: 1em 0;
      width: 25%;
      text-align: center;
      //&:nth-child(even) {
      //   text-align: left;
      //}
   }
   .badge[href]:focus img,
   .badge[href]:hover img {
      opacity: 0.8;
   }

   @include media-breakpoint-up(sm) {
      .badge{
         padding: 1em;
         width: auto;
      }
   }

   @include media-breakpoint-up(md) {
      padding: 3em 0;
      justify-content: left;
   }

   @include media-breakpoint-up(md) {
      .badge {
         padding: 0.25em 0.6em;
         // Align first item with the text left border
         &:first-child {
            padding-left: 0;
         }
      }
   }
   @include media-breakpoint-up(lg) {
      padding: 2em 0 0 0; // the botom padding is solved in text-wrapper
   }
}
